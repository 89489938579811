<template>
  <nav
    class="flex h-full px-8 lg:px-0 items-center bg-base2 bar-shadow lg:flex-grow lg:flex-col rounded-b-3xl lg:rounded-none lg:rounded-r-3xl lg:w-12"
  >
    <div class="flex justify-between lg:flex-col flex-grow">
      <sidebar-icon
        label="Your next meeting"
        dest="now"
        supportUrl="https://help.meetric.app/en/articles/5240521-my-next-meeting"
      >
        <!-- Meetric logo icon -->
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 27 28"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g>
            <path
              d="M14,0 C6.2680135,0 0,6.2680135 0,14 C0,21.7319865 6.2680135,28 14,28"
              fill="#FFFFFF"
            ></path>
            <path
              d="M21,7 C12.7157288,7 6,13.0441559 6,20.5 C6,27.9558441 12.7157288,34 21,34"
              transform="translate(13.500000, 20.500000) rotate(-90.000000) translate(-13.500000, -20.500000) "
              fill="#FFFFFF"
            ></path>
          </g>
        </svg>
      </sidebar-icon>

      <sidebar-icon
        label="Meetings"
        dest="meetings"
        supportUrl="https://help.meetric.app/en/articles/4793992-meeting-list-overview"
      >
        <svg
          width="20px"
          height="20px"
          viewBox="-0.5 0 17 21"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Symbols"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="Icon/Calendar/Line"
              transform="translate(-3.000000, -2.000000)"
              fill="#FFFFFF"
            >
              <path
                d="M17.9999999,4 L16.9999999,4 L16.9999999,3 L16.9999999,3 C16.9999999,2.447715 16.552285,2 15.9999999,2 C15.447715,2 14.9999999,2.447715 14.9999999,3 L14.9999999,4 L8.99999987,4 L8.99999987,3 L8.99999987,3 C8.99999987,2.447715 8.552285,2 7.99999987,2 C7.447715,2 6.99999987,2.447715 6.99999987,3 L6.99999987,4 L5.99999987,4 L5.99999987,4 C4.34314987,4 2.99999987,5.34315 2.99999987,7 C2.99999987,7 2.99999987,7 2.99999987,7 L2.99999987,19 L2.99999987,19 C2.99999987,20.6568495 4.34314987,22 5.99999987,22 L17.9999999,22 L17.9999999,22 C19.6568497,22 20.9999999,20.6568495 20.9999999,19 L20.9999999,7 L20.9999999,7 C20.9999999,5.34314955 19.6568497,4 17.9999999,4 L17.9999999,4 Z M5.99999996,6 L6.99999996,6 L6.99999996,7 L6.99999996,7 C6.99999996,7.55228515 7.447715,8 7.99999996,8 C8.552285,8 8.99999996,7.55228515 8.99999996,7 L8.99999996,6 L15,6 L15,7 L15,7 C15,7.5522853 15.447715,8 16,8 C16.552285,8 17,7.5522853 17,7 L17,6 L18,6 L18,6 C18.552285,6 19,6.4477153 19,7 L19,11 L4.99999996,11 L4.99999996,7 L4.99999996,7 C4.99999996,6.44771545 5.44771496,6 5.99999996,6 L5.99999996,6 Z M18,20 L5.99999996,20 L5.99999996,20 C5.44771496,20 4.99999996,19.552285 4.99999996,19 C4.99999996,19 4.99999996,19 4.99999996,19 L4.99999996,13 L19,13 L19,19 L19,19 C19,19.552285 18.552285,20 18,20 L18,20 Z"
                id="Fill"
              ></path>
            </g>
          </g>
        </svg>
      </sidebar-icon>

      <sidebar-icon
        label="Actions"
        dest="actions"
        supportUrl="https://help.meetric.app/en/articles/4798211-action-list-overview"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#FFFFFF"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2.1"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </sidebar-icon>

      <sidebar-icon
        label="Search"
        dest="search"
        @click="$emit('toggleSearch')"
        supportUrl="https://help.meetric.app/en/articles/5549966-search"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#FFFFFF"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2.1"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </sidebar-icon>

      <div class="hidden lg:flex lg:flex-col lg:flex-grow"></div>

      <!--
      <sidebar-icon
        v-show="!extensionInstalled"
        label="Meetric Chrome extension"
        dest="chromeextension"
        class="hidden lg:block"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          stroke-width="1.8"
          stroke="#fff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="9" />
          <circle cx="12" cy="12" r="3" />
          <line x1="12" y1="9" x2="20.4" y2="9" />
          <line x1="12" y1="9" x2="20.4" y2="9" transform="rotate(120 12 12)" />
          <line x1="12" y1="9" x2="20.4" y2="9" transform="rotate(240 12 12)" />
        </svg>
      </sidebar-icon>
      -->

      <sidebar-icon
        id="intercom-launch"
        label="Help center"
        dest="help"
        supportUrl="https://help.meetric.app/"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
            fill="rgba(255,255,255,1)"
          />
        </svg>
      </sidebar-icon>

      <sidebar-icon
        class="lg:mb-8"
        label="Your settings"
        dest="profile"
        supportUrl="https://help.meetric.app/en/articles/5240568-your-settings"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            fill="#FFFFFF"
            d="M2 12c0-.865.11-1.703.316-2.504A3 3 0 0 0 4.99 4.867a9.99 9.99 0 0 1 4.335-2.505 3 3 0 0 0 5.348 0 9.99 9.99 0 0 1 4.335 2.505 3 3 0 0 0 2.675 4.63c.206.8.316 1.638.316 2.503 0 .865-.11 1.703-.316 2.504a3 3 0 0 0-2.675 4.629 9.99 9.99 0 0 1-4.335 2.505 3 3 0 0 0-5.348 0 9.99 9.99 0 0 1-4.335-2.505 3 3 0 0 0-2.675-4.63C2.11 13.704 2 12.866 2 12zm4.804 3c.63 1.091.81 2.346.564 3.524.408.29.842.541 1.297.75A4.993 4.993 0 0 1 12 18c1.26 0 2.438.471 3.335 1.274.455-.209.889-.46 1.297-.75A4.993 4.993 0 0 1 17.196 15a4.993 4.993 0 0 1 2.77-2.25 8.126 8.126 0 0 0 0-1.5A4.993 4.993 0 0 1 17.195 9a4.993 4.993 0 0 1-.564-3.524 7.989 7.989 0 0 0-1.297-.75A4.993 4.993 0 0 1 12 6a4.993 4.993 0 0 1-3.335-1.274 7.99 7.99 0 0 0-1.297.75A4.993 4.993 0 0 1 6.804 9a4.993 4.993 0 0 1-2.77 2.25 8.126 8.126 0 0 0 0 1.5A4.993 4.993 0 0 1 6.805 15zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
          />
        </svg>
      </sidebar-icon>
    </div>
  </nav>
</template>

<script>
import '@/assets/styles/index.css';
import SidebarIcon from './SidebarIcon';

export default {
  name: 'SideBar',
  components: {
    SidebarIcon,
  },
  computed: {},
  methods: {},
};
</script>
<style scoped>
.bar-shadow {
  box-shadow: 0.2rem 0.2rem 0.7rem 0.1rem rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0.2rem 0.2rem 0.7rem 0.1rem rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0.2rem 0.2rem 0.7rem 0.1rem rgba(0, 0, 0, 0.1);
}
.dark nav {
  background-color: rgb(51, 51, 51);
}
</style>
