<template>
  <div>
    <transition appear name="slide">
      <side-bar
        v-if="isAuthenticated"
        @toggleSearch="$emit('toggleSearch')"
      ></side-bar>
      <side-bar-no-auth v-if="!isAuthenticated"></side-bar-no-auth>
    </transition>
  </div>
</template>

<script>
import SideBar from '@/components/Layout/SideBar.vue';
import SideBarNoAuth from '@/components/Layout/SideBarNoAuth.vue';

export default {
  name: 'NavigationBar',
  components: {
    SideBar,
    SideBarNoAuth,
  },
  computed: {
    isAuthenticated() {
      return this.$gAuth.isAuthorized;
    },
  },
};
</script>

<style scoped>
.slide-enter-active {
  transition: transform 0.8s ease;
}
.slide-enter {
  @apply transform -translate-y-full translate-x-0 lg:-translate-x-full lg:translate-y-0;
}
</style>
